<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <b-row>
              <b-col>
                <h1>User Availabilities</h1>
              </b-col>
              <b-col cols="12" md="6" class="text-right">
                <b-button size="sm" variant="primary" @click="addModal()">
                  Add
                </b-button>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group>
                  <DxDataGrid
                    key-expr="id"
                    id="gridContainer"
                    :data-source="dataSource"
                    :focused-row-enabled="true"
                    :allow-column-reordering="true"
                    :allow-column-resizing="true"
                    :column-auto-width="true"
                    :show-borders="true"
                    :row-alternation-enabled="true"
                    @row-removed="handleEvent('row-removed', $event)"
                    @row-updated="handleEvent('row-updated', $event)"
                    @edit-canceling="handleEvent('edit-canceling', $event)"
                    @edit-canceled="handleEvent('edit-canceled', $event)"
                    @row-click="handleEvent('row-click', $event)"
                  >
                    <DxPaging :page-size="totalCount" />

                    <DxColumnChooser :allow-search="true" :enabled="true" />
                    <DxColumnFixing :enabled="true" />
                    <DxSorting mode="multiple" />
                    <DxFilterRow :visible="true" />
                    <DxGroupPanel :visible="true" />
                    <DxGrouping :auto-expand-all="true" />
                    <DxHeaderFilter :visible="true" />
                    <DxSearchPanel location="before" :visible="true" />

                    <DxEditing
                      :allow-updating="true"
                      :allow-deleting="true"
                      :use-icons="true"
                      mode="row"
                    />

                    <DxColumn
                      :data-field="item.field"
                      :caption="item.caption"
                      v-for="(item, index) in dataColumns"
                      :key="index"
                    />

                    <DxColumn type="buttons">
                      <DxButton icon="edit" hint="Edit" :onClick="onEdit" />
                      <DxButton name="delete" />
                    </DxColumn>

                    <DxToolbar>
                      <DxItem location="before" name="columnChooserButton" />
                      <DxItem location="before" name="searchPanel" />
                      <DxItem name="groupPanel" />
                    </DxToolbar>
                  </DxDataGrid>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-nav>
                  <b-nav-item
                    @click="onSetPage(page)"
                    v-for="(page, index) in pageSize"
                    :key="index"
                  >
                    <span :class="page.isActive ? 'text-success' : ''">
                      {{ page.name }}
                    </span>
                  </b-nav-item>
                </b-nav>
              </b-col>
              <b-col>
                <b-pagination
                  align="right"
                  v-model="currentPage"
                  :total-rows="totalCount"
                  :per-page="perPage"
                  first-text="First"
                  prev-text="Prev"
                  next-text="Next"
                  last-text="Last"
                />
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModal"
      centered
      @hide="closeModal()"
      size="lg"
      no-close-on-backdrop
    >
      <b-row>
        <b-col>
          <b-form-group label="User" label-for="v-username" class="">
            <v-select
              id="v-username"
              v-model="formData.username"
              :options="users"
              :reduce="(users) => users.value"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Date" label-for="v-start_date">
            <b-form-input
              id="v-start_date"
              v-model="formData.preview_start_date"
              type="datetime-local"
              class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Date" label-for="v-end_date">
            <b-form-input
              id="v-end_date"
              v-model="formData.preview_end_date"
              type="datetime-local"
              class="form-control"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group label="Remarks" label-for="v-remarks">
            <b-form-textarea
              id="v-remarks"
              v-model="formData.remarks"
              class="form-control"
              rows="4"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button
          variant="danger"
          class="bg-darken-4"
          size="sm"
          @click="closeModal()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          class="bg-darken-4"
          size="sm"
          @click="onSubmit()"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import usersApi from '@api/users';
import userAvailabiltiesApi from '@api/user_availabilities';
import { queryParameters } from '@/schema';

export default {
  name: 'UserPage',
  components: {},
  data: () => ({
    showModal: false,
    currentPage: 1,
    perPage: 0,
    totalCount: 0,
    page: 0,
    size: 0,
    pageSize: [
      { name: '10', value: 10, isActive: false },
      { name: '20', value: 20, isActive: false },
      { name: '50', value: 50, isActive: false },
      { name: '100', value: 100, isActive: false },
      { name: 'ALL', value: 10000, isActive: false },
    ],
    formData: {
      id: 0,
      username: null,
      start_date: null,
      end_date: null,
      remark: null,

      preview_start_date: null,
      preview_end_date: null,
    },
    users: [],
    dataSource: [],
    dataColumns: [
      { caption: 'User', field: 'username' },
      { caption: 'Start Date', field: 'display_start_date' },
      { caption: 'End Date', field: 'display_end_date' },
      { caption: 'Remarks', field: 'remarks' },
    ],
  }),
  watch: {
    page(v) {
      this.loadUserAvailabilities();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadUserAvailabilities();
      }
    },
  },
  mounted() {
    this.loadUsers();

    this.loadUserAvailabilities();
  },
  methods: {
    loadUserAvailabilities() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.page.size = this.size;
      params.page.after = this.page;

      userAvailabiltiesApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            const display = (date) => {
              return this.displayDateTimeFromUnix(date);
            };

            for (const item of data) {
              const { start_date, end_date } = item;
              item.display_start_date = display(start_date);
              item.display_end_date = display(end_date);
              item.preview_start_date = this.inputDateTimeFromUnix(start_date);
              item.preview_end_date = this.inputDateTimeFromUnix(end_date);
            }

            this.dataSource = data;

            this.totalCount = meta.page.total;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadUsers() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
        filterExt: `roles=user&coyuser&custuser&tech&qcsemployee`,
      };

      usersApi
        .list(params)
        .then(({ data }) => {
          if (data != null) {
            for (const item of data) {
              const { first_name, last_name, username: value } = item;

              let label = first_name || username;
              if (first_name && last_name) {
                label += ` ${last_name}`;
              }

              this.users.push({ label, value });
            }
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onEdit(item) {
      const { row } = item;

      if (row) {
        const { data } = row;
        const replace24 = (date) => {
          return date.includes('T24') ? date.replace('T24', 'T00') : date;
        };

        const endDate = this.inputDateTimeFromUnix(data.end_date);
        const startDate = this.inputDateTimeFromUnix(data.start_date);
        data.preview_start_date = replace24(startDate);
        data.preview_end_date = replace24(endDate);

        this.formData = data;
        this.showModal = true;
      }
    },
    onSubmit() {
      const { preview_start_date, preview_end_date } = this.formData;
      this.formData.start_date = this.inputDateTimeToUnix(preview_start_date);
      this.formData.end_date = this.inputDateTimeToUnix(preview_end_date);

      let app = null;

      if (this.formData.id) {
        app = userAvailabiltiesApi.update(this.formData);
      } else {
        app = userAvailabiltiesApi.add(this.formData);
      }

      if (app) {
        app
          .then(({ data }) => {
            this.loadUserAvailabilities();
          })
          .catch(() => {
            //
          })
          .finally(() => {
            this.closeModal();
          });
      }
    },
    addModal() {
      this.showModal = true;
      this.formData.preview_start_date = this.inputDateTimeToLocale();
      this.formData.preview_end_date = this.inputDateTimeToLocale();
    },
    closeModal() {
      this.showModal = false;
      this.formData = {
        id: 0,
        username: null,
        start_date: null,
        end_date: null,
        remark: null,

        preview_start_date: null,
        preview_end_date: null,
      };
    },
    handleEvent(action, event) {
      console.log(action, event);
    },
  },
};
</script>

<style></style>
