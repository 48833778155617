import api from '@apiInstance';

export default {
  add(data) {
    return api.post('/user_availabilities', data);
  },
  get(id) {
    return api.fetch('/user_availabilities/' + id);
  },
  list(params) {
    return api.fetch('/user_availabilities', { params });
  },
  update(data) {
    return api.patch('/user_availabilities', data);
  },
  delete(id) {
    return api.remove('/user_availabilities', id);
  },
};
